import SweetScroll from 'sweet-scroll'
import Splide from '@splidejs/splide'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const topPage = document.getElementById('top')
const fvSplide = document.querySelector('.fv__splide')
const equipmentSplide = document.querySelector('.equipment__splide')
const tel = document.querySelector('.tel')

// スムーススクロールの設定
const scroller = new SweetScroll()

scroller.update({
  trigger: 'a[href^="#"]',
  duration: 600,
  easing: 'easeOutQuint',
  offset: 0,
  vertical: true,
  horizontal: false,
  cancellable: true,
  updateURL: false,
  preventDefault: true,
  stopPropagation: true,
})

// 追従ボタン
if (tel) {
  fixedButton()
}

function fixedButton() {
  gsap.registerPlugin(ScrollTrigger)

  const fv = document.getElementById('fv')
  const footer = document.querySelector('.footer')

  gsap.to(tel, {
    scrollTrigger: {
      trigger: fv,
      // markers: true,
      start: 'bottom top',
      toggleActions: 'play none none reverse',
      onEnter: () => {
        tel.classList.remove('hidden')
      },
      onLeaveBack: () => {
        tel.classList.add('hidden')
      },
    },
  })

  gsap.to(tel, {
    scrollTrigger: {
      trigger: footer,
      // markers: true,
      start: 'top bottom',
      toggleActions: 'play none none reverse',
      onEnter: () => {
        tel.classList.add('hidden')
      },
      onLeaveBack: () => {
        tel.classList.remove('hidden')
      },
    },
  })
}

// スクロールしたときに、headerメニューにアイコンを付与
if (topPage) {
  scrollMenu()
}

function scrollMenu() {
  const menuLink = document.querySelectorAll('[data-link="link"]')
  const menuSection = document.getElementById('menu')
  const flowSection = document.getElementById('flow')
  const priceSection = document.getElementById('price')
  const equipmentSection = document.getElementById('equipment')
  const infoSection = document.getElementById('info')
  const accessSection = document.getElementById('access')
  const sections = [
    menuSection,
    flowSection,
    priceSection,
    equipmentSection,
    infoSection,
    accessSection,
  ]

  gsap.registerPlugin(ScrollTrigger)

  sections.forEach((elm, index) => {
    gsap.to(elm, {
      scrollTrigger: {
        trigger: elm,
        // markers: true,
        start: 'top 20%',
        end: 'bottom 20%',
        toggleActions: 'play none none reverse',
        onEnter: () => {
          menuLink[index].classList.add('header__menu__hands')
        },
        onLeaveBack: () => {
          menuLink[index].classList.remove('header__menu__hands')
        },
        onLeave: () => {
          menuLink[index].classList.remove('header__menu__hands')
        },
        onEnterBack: () => {
          menuLink[index].classList.add('header__menu__hands')
        },
      },
    })
  })
}

// FirstView スライダー
if (fvSplide) {
  fvSlider()
}

function fvSlider() {
  const splide = new Splide(fvSplide, {
    type: 'fade',
    speed: 3000,
    autoplay: true,
    interval: 3000,
    rewind: true,
    drag: false,
    pagination: false,
    arrows: false,
  })
  splide.mount()
}

// Equipment スライダー
if (equipmentSplide) {
  equipmentSlider()
}

function equipmentSlider() {
  const arrowRight = document.querySelector('.equipment__arrow__image.right')
  const arrowLeft = document.querySelector('.equipment__arrow__image.left')

  const splide = new Splide(equipmentSplide, {
    type: 'loop',
    pagination: false,
    arrows: false,
    gap: '1.6rem',
  })
  splide.mount()

  // 右へスライド
  arrowRight.addEventListener('click', () => {
    splide.go('+1')
  })

  // 左へスライド
  arrowLeft.addEventListener('click', () => {
    splide.go('-1')
  })
}

// header メニュー
headerMenu()
function headerMenu() {
  const body = document.querySelector('body')
  const headerMenuOpen = document.querySelector('.header__open')
  const headerMenuText = document.querySelector('.header__open p')
  const headerMenuButton = document.querySelector('.header__open__button')
  const headerMenuBorder = document.querySelectorAll(
    '.header__open__button span'
  )
  const headerMenuContent = document.querySelector('.header__content')
  const headerMenuLink = document.querySelectorAll('.header__content a')

  // メニューボタンクリックイベント
  headerMenuOpen.addEventListener('click', function () {
    headerMenuContent.classList.toggle('active')

    if (headerMenuContent.classList.contains('active')) {
      body.classList.add('active')
      headerMenuText.textContent = 'とじる'
      headerMenuButton.classList.add('active')

      headerMenuBorder.forEach(function (elm) {
        elm.classList.add('active')
      })
    } else {
      body.classList.remove('active')
      headerMenuText.textContent = 'メニュー'
      headerMenuButton.classList.remove('active')

      headerMenuBorder.forEach(function (elm) {
        elm.classList.remove('active')
      })
    }
  })

  // メニューコンテンツ リンククリックイベント
  headerMenuLink.forEach(function (elm) {
    elm.addEventListener('click', function () {
      body.classList.remove('active')
      headerMenuContent.classList.remove('active')
      headerMenuText.textContent = 'メニュー'
      headerMenuButton.classList.remove('active')

      headerMenuBorder.forEach(function (elm) {
        elm.classList.remove('active')
      })
    })
  })
}
